import React, { forwardRef, useContext, useEffect, useImperativeHandle, useLayoutEffect, useRef } from 'react'
import { Context } from './KlineChartParent'
import { IChartApi, ISeriesApi } from 'lightweight-charts'
const KChartVolumeSeries = forwardRef((props: any, ref) => {
  const parent = useContext(Context) as { _api: IChartApi; api: () => IChartApi; free: () => void }
  const context = useRef<{
    _api?: ISeriesApi<'Histogram'>
    api: () => ISeriesApi<'Histogram'>
    free: () => void
  }>({
    api() {
      if (!this._api) {
        const { children, data, autoscaleInfoProvider, priceFormat, ...rest } = props
        this._api = parent.api().addHistogramSeries({
          color: '#26a69a',
          priceLineWidth: 1,
          base: 0,
          overlay: true,
          priceFormat:
            priceFormat && typeof priceFormat === 'object'
              ? priceFormat
              : {
                  type: 'volume',
                  precision: 2,
                  minMove: 0.01
                },
          autoscaleInfoProvider:
            autoscaleInfoProvider && typeof autoscaleInfoProvider === 'function'
              ? autoscaleInfoProvider
              : (original: any) => {
                  const res = original()
                  if (res !== null) {
                    res.priceRange.minValue -= 0.01
                    res.priceRange.maxValue += 0.01
                  }
                  return res
                },
          lastValueVisible: false,
          priceLineVisible: false,
          ...rest
        })
      }
      return this._api
    },
    free() {
      if (this._api) {
        parent && parent.free()
      }
    }
  })
  useEffect(() => {
    if (!props.scaleMargins) {
      return
    }
    const currentRef = context.current
    currentRef
      .api()
      .priceScale()
      .applyOptions({
        scaleMargins: props.scaleMargins
      })
  }, [props.scaleMargins])
  useEffect(() => {
    if (props.visible === undefined) {
      return
    }
    const currentRef = context.current
    currentRef.api().applyOptions({
      visible: props.visible
    })
  }, [props.visible])

  useEffect(() => {
    if (!props.priceScaleId) {
      return
    }
    const currentRef = context.current
    currentRef.api().applyOptions({
      priceScaleId: props.priceScaleId
    })
  }, [props.priceScaleId])

  useLayoutEffect(() => {
    const currentRef = context.current
    const { children, data, scaleMargins, ...rest } = props
    currentRef.api().applyOptions({
      overlay: true,
      ...rest
    })
  })

  useImperativeHandle(ref, () => context.current.api(), [])

  return <Context.Provider value={context.current}>{props.children}</Context.Provider>
})
export default KChartVolumeSeries
