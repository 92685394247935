import { createReducer } from '@reduxjs/toolkit'
import { setZIndex, setCoinInfo } from './actions'
import { CoinExType } from '../../api/swap/klineData'

export interface QuotesState {
  readonly zIndex: number
  // the typed recipient address or ENS name, or null if swap should go to sender
  readonly coinInfo: CoinExType
}

const initialState: QuotesState = {
  zIndex: 70,
  coinInfo: {
    id: 0,
    token0Symbol: '',
    token1Symbol: '',
    token0Logo: '',
    token1Logo: '',
    price: '0.000',
    priceDecimals: 4,
    holders: '0',
    famc: '0.000',
    // eslint-disable-next-line @typescript-eslint/camelcase
    change_24h: '0.000',
    isTrue: false,
    info24h: {
      maxPrice: '0.000',
      minPrice: '0.000',
      vol: '0.000',
      volValue: '0.000'
    }
  }
}

export default createReducer<QuotesState>(initialState, builder =>
  builder
    .addCase(setZIndex, (state, { payload: { zIndex } }) => {
      state.zIndex = zIndex
    })
    .addCase(setCoinInfo, (state, { payload }) => {
      state.coinInfo = payload.coinInfo
    })
)
