import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const QuotesTradeListWTitleContainer = styled.div`
  display: block;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  padding: 10px 16px;
  background: ${({ theme }) => theme.bg1};
  border-bottom: 1px solid ${({ theme }) => theme.border1};
`
const QuotesTradeListWTitle = styled.span<{ isActive?: boolean }>`
  color: ${({ theme, isActive }) => (isActive ? 'rgb(243, 132, 30)' : theme.text1)};
  font-size: 14px;
  align-items: center;
  margin: 0;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'lighter')};
  display: inline-block;
  margin-right: 20px;
`

const NavsContainer = styled.div`
  position: relative;
`
const NavsLine = styled.div<{ width: number; left: number }>`
  position: absolute;
  bottom: 0;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: rgb(243, 132, 30);
  height: 1.5px;
  left: ${({ left }) => left + 'px'};
  width: ${({ width }) => width + 'px'};
`
function Navs({ isActive, setActiveItem }: { isActive: string; setActiveItem: (str: string) => void }) {
  const { t } = useTranslation()
  const [domParams, setDomParams] = useState({
    width: 0,
    left: 0
  })
  const ulRef = useRef<any>(null)
  function onSelectItem(event: any, index: string) {
    const { clientWidth, offsetLeft } = event.target
    setActiveItem(index)
    setDomParams({
      width: clientWidth,
      left: offsetLeft
    })
  }
  useEffect(() => {
    if (!isActive) {
      return
    }
    const childrenDom = ulRef.current && ulRef.current.children[isActive]
    setDomParams({
      width: childrenDom.clientWidth,
      left: childrenDom.offsetLeft
    })
  }, [])
  return (
    <NavsContainer>
      <QuotesTradeListWTitleContainer ref={ulRef}>
        <QuotesTradeListWTitle
          isActive={isActive === '0'}
          onClick={event => {
            onSelectItem(event, '0')
          }}
        >
          {t('委托订单')}
        </QuotesTradeListWTitle>
        <QuotesTradeListWTitle
          isActive={isActive === '1'}
          onClick={event => {
            onSelectItem(event, '1')
          }}
        >
          {t('最新成交')}
        </QuotesTradeListWTitle>
        <QuotesTradeListWTitle
          isActive={isActive === '2'}
          onClick={event => {
            onSelectItem(event, '2')
          }}
        >
          {t('信息')}
        </QuotesTradeListWTitle>
        {/*<QuotesTradeListWTitle isActive={curState === '3'} onClick={() => setCurState('3')}>*/}
        {/*  {t('数据')}*/}
        {/*</QuotesTradeListWTitle>*/}
      </QuotesTradeListWTitleContainer>
      <NavsLine width={domParams.width} left={domParams.left}></NavsLine>
    </NavsContainer>
  )
}

export default Navs
