import React from 'react'
import QuotesFlex from '../../components/quotes/QuotesFlex'
import { RouteComponentProps } from 'react-router'
import styled from 'styled-components'
import { QuotesTradeList } from '../../components/quotes/QuotesTradeList'
import { parse } from 'qs'
import Toggle from '../../components/Toggle'
import Language from '../../components/Language'
import { useDarkModeManager } from '../../state/user/hooks'
import QuotesButton from '../../components/quotes/QuotesButton'

const QuotesFlexWrapContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  z-index: 0;
  display: flex;
  flex-direction: column;
`
// history,
export default function Quotes({
  match: {
    params: { id }
  },
  location: { search }
}: RouteComponentProps<{ id: string }>) {
  const [darkMode, toggleSetDarkMode] = useDarkModeManager()
  const parsed = parse(search, {
    parseArrays: false,
    ignoreQueryPrefix: true
  })

  const { isHide, decimals } = parsed

  return (
    <>
      <QuotesFlexWrapContainer>
        <QuotesFlex
          id={id}
          decimals={decimals as string}
          childrenRightTop={
            typeof isHide === 'string' && isHide === 'false' ? (
              <>
                <Toggle id={'theme'} isActive={darkMode} toggle={toggleSetDarkMode}></Toggle>
                <Language></Language>
              </>
            ) : (
              <></>
            )
          }
        ></QuotesFlex>
        <QuotesTradeList id={id} decimals={decimals as string}></QuotesTradeList>
      </QuotesFlexWrapContainer>
      <QuotesButton id={id}></QuotesButton>
    </>
  )
}
