import { AppDispatch, AppState } from '../index'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { setCoinInfo, setZIndex } from './actions'
import { CoinExType } from '../../api/swap/klineData'

export function useQuotesState(): AppState['quotes'] {
  return useSelector<AppState, AppState['quotes']>(state => state.quotes)
}
export function useQuotesActionHandlers(): {
  onChangeZIndex: (zIndex: number) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const onChangeZIndex = useCallback(
    (zIndex: number) => {
      dispatch(setZIndex({ zIndex }))
    },
    [dispatch]
  )
  return {
    onChangeZIndex
  }
}
export function useQuotesCoinInfoState(): CoinExType {
  return useSelector<AppState, AppState['quotes']['coinInfo']>(state => state.quotes.coinInfo)
}
export function useQuotesActionCoinInfo(): {
  coinInfo: CoinExType
  setCoinBaseInfo: (params: CoinExType) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const coinInfo = useQuotesCoinInfoState()
  const setCoinBaseInfo = useCallback(
    (params: CoinExType) => {
      dispatch(setCoinInfo({ coinInfo: params }))
    },
    [dispatch]
  )
  return { coinInfo, setCoinBaseInfo }
}
