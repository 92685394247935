import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useIncomeIndexHook } from './hook/QuotesTradeListHook'
// import { QuotesFlexWrapProps } from './QuotesFlex'
import { BigNumber } from 'bignumber.js'
const IncomeIndexUl = styled.ul`
  width: 100%;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 7px 0;
`
const IncomeIndexLi = styled.li`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  list-style: none;
`
const IncomeIndexSpan = styled.span<{ isNaN: boolean; color: string }>`
  text-align: center;
  font-size: 10px;
  color: ${({ theme, color, isNaN }) => {
    if (isNaN) {
      return theme.text3
    }
    return color
  }};
`
const IncomeIndexP = styled.p`
  margin: 0;
  margin-bottom: 5px;
  text-align: center;
  font-size: 10px;
  color: ${({ theme }) => theme.text3};
`
const IncomeIndex = function({ id }: { id: string }) {
  const { t } = useTranslation()
  const { data } = useIncomeIndexHook(id)
  return (
    <>
      <IncomeIndexUl>
        <IncomeIndexLi>
          <IncomeIndexP>{t('今日')}</IncomeIndexP>
          <IncomeIndexSpan
            isNaN={new BigNumber(data.day_increase).isNaN()}
            color={new BigNumber(data.day_increase).gte(0) ? 'rgba(0, 150, 136, 0.6)' : 'rgba(255,82,82, 0.6)'}
          >
            {data.day_increase}
            {new BigNumber(data.day_increase).isNaN() ? '' : '%'}
          </IncomeIndexSpan>
        </IncomeIndexLi>
        <IncomeIndexLi>
          <IncomeIndexP>{t('7 天')}</IncomeIndexP>
          <IncomeIndexSpan
            isNaN={new BigNumber(data.week_increase).isNaN()}
            color={new BigNumber(data.week_increase).gte(0) ? 'rgba(0, 150, 136, 0.6)' : 'rgba(255,82,82, 0.6)'}
          >
            {data.week_increase}
            {new BigNumber(data.week_increase).isNaN() ? '' : '%'}
          </IncomeIndexSpan>
        </IncomeIndexLi>
        <IncomeIndexLi>
          <IncomeIndexP>{t('30 天')}</IncomeIndexP>
          <IncomeIndexSpan
            isNaN={new BigNumber(data.month_increase).isNaN()}
            color={new BigNumber(data.month_increase).gte(0) ? 'rgba(0, 150, 136, 0.6)' : 'rgba(255,82,82, 0.6)'}
          >
            {data.month_increase}
            {new BigNumber(data.month_increase).isNaN() ? '' : '%'}
          </IncomeIndexSpan>
        </IncomeIndexLi>
        <IncomeIndexLi>
          <IncomeIndexP>{t('90 天')}</IncomeIndexP>
          <IncomeIndexSpan
            isNaN={new BigNumber(data.three_month_increase).isNaN()}
            color={new BigNumber(data.three_month_increase).gte(0) ? 'rgba(0, 150, 136, 0.6)' : 'rgba(255,82,82, 0.6)'}
          >
            {data.three_month_increase}
            {new BigNumber(data.three_month_increase).isNaN() ? '' : '%'}
          </IncomeIndexSpan>
        </IncomeIndexLi>
        {/*<IncomeIndexLi>*/}
        {/*  <IncomeIndexP>{t('180 天')}</IncomeIndexP>*/}
        {/*  <IncomeIndexSpan>{data.day_increase}</IncomeIndexSpan>*/}
        {/*</IncomeIndexLi>*/}
        <IncomeIndexLi>
          <IncomeIndexP>{t('1 年')}</IncomeIndexP>
          <IncomeIndexSpan
            isNaN={new BigNumber(data.year_increase).isNaN()}
            color={new BigNumber(data.year_increase).gte(0) ? 'rgba(0, 150, 136, 0.6)' : 'rgba(255,82,82, 0.6)'}
          >
            {data.year_increase}
            {new BigNumber(data.year_increase).isNaN() ? '' : '%'}
          </IncomeIndexSpan>
        </IncomeIndexLi>
      </IncomeIndexUl>
    </>
  )
}

export default IncomeIndex
