const darkTheme = {
  chart: {
    layout: {
      background: {
        type: 'solid',
        color: 'transparent'
      },
      lineColor: '#2c2c2e',
      textColor: '#737375',
      fontSize: 13
    },
    crosshair: {
      vertLine: {
        width: 1,
        color: '#C3BCDB44'
      },
      horzLine: {
        color: '#C3BCDB44'
      }
    },
    grid: {
      vertLines: {
        color: '#3b3b3b',
        visible: true,
        style: 1
      },
      horzLines: {
        color: '#3b3b3b',
        visible: true
      }
    }
  }
}

const lightTheme = {
  chart: {
    layout: {
      background: {
        type: 'solid',
        color: 'transparent'
      },
      lineColor: '#f2f2f2',
      textColor: '#9a9a9a',
      fontSize: 13
    },
    grid: {
      vertLines: {
        color: '#f0f3fa'
      },
      horzLines: {
        color: '#f0f3fa'
      }
    }
  }
}
// export enum
export const KLineIndicatorsConfig = {
  MA: [5, 10, 30],
  EMA: [5, 10, 20],
  BOLL: ['MIDDLE', 'UPPER', 'LOWER'],
  MACD: ['MACD', 'DIF', 'DEA'],
  KDJ: ['K', 'D', 'J'],
  RSI: [14]
}
export enum ThemeEnum {
  Dark = 'Dark',
  Light = 'Light'
}

export type ThemeKey = keyof typeof ThemeEnum
export const themesData: { [key in ThemeEnum]: any } = {
  Dark: darkTheme,
  Light: lightTheme
}
