// 默认显示 模块
export const defaultOptions: string[] = ['1Min', '5Min', '15Min', '30Min', '1Hour', '1Day', '1Week']

// '1Month'
export const otherOptions: string[] = ['2Hour', '4Hour']
// 可提供选择模块 'EMA',
export const mainIndicator: string[] = ['MA', 'BOLL']

// 'MACD',
export const subIndicator: string[] = ['MACD', 'KDJ', 'RSI', 'WR']
export const allSelections: string[] = [
  '1Sec',
  '1Min',
  '3Min',
  '5Min',
  '15Min',
  '30Min',
  '1Hour',
  '2Hour',
  '4Hour',
  '6Hour',
  '12Hour',
  '3Day',
  '1Week',
  '1Month'
]
