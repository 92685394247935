import { ChainId } from '../api/common'

// returns the checksummed address if the address is valid, otherwise returns false

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  // 1: 'https://etherscan.io',
  // 3: 'https://ropsten.etherscan.io',
  // 4: 'https://rinkeby.etherscan.io',
  // 5: 'https://goerli.etherscan.io',
  // 42: 'https://kovan.etherscan.io',
  56: 'https://bscscan.com',
  11552: 'https://ompchain.com'
}

export function getEtherscanLink(chainId: ChainId, data: string, type: 'transaction' | 'token' | 'address'): string {
  const prefix = `${ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[56]}`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  // return `${address.substring(0, chars + 2)}...${address.substring(42 - chars)}`
  return `${address.slice(0, chars + 2)}...${address.slice(address.length - chars)}`
}

// 去重
export function unique2(arr: any[]) {
  return arr.filter((num: any, index: number) => {
    return arr.indexOf(num) === index
  })
}

// account is not optional

// account is optional
