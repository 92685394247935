import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { BarPrice, MouseEventParams, SeriesMarker, Time } from 'lightweight-charts'
import KChartVolumeSeries from './KChartVolumeSeries'
import KChartCandleSeries from './KChartCandleSeries'
import KChartLineSeries from './KChartLineSeries'
import {
  calculateBoll,
  calculateKDJ,
  calculateMACD,
  calculateRSi,
  calculateSMA,
  calculateWR,
  filterKlineDataToHL,
  filterKlineDataToVol,
  findFirstNonZeroIndex,
  findMaxAndMinValues
} from './common'
import { mainIndicator, subIndicator } from './static'
import { bigNumberTransform, formTimeStamp2DateTime3 } from '../../utils/common'
import ChartContainer from './ChartContainer'
import { useTranslation } from 'react-i18next'
import { useDarkModeManager } from '../../state/user/hooks'
import { BigNumber } from 'bignumber.js'
import KChartMACDSeries from './KChartMACDSeries'
import { SettingChartType } from './hook2'
import { isMobile } from 'react-device-detect'
import Loader from '../Loader'
const KLineChartMask = styled.div<{ isShow: boolean }>`
  position: absolute;
  z-index: 2;
  display: ${({ isShow }) => (!isShow ? 'flex' : 'none')};
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.bg1};
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
`
const KLineChartContainer = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  padding-bottom: 30px;

  .sma-legend {
    width: 96px;
    height: 70px;
    position: absolute;
    padding: 8px;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.23);
    text-align: left;
    z-index: 1000;
    pointer-events: none;
  }
`
const KlineIndicatorContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 32px;
  line-height: 32px;
`
const KlineIndicatorItem = styled.div<{ num?: number }>`
  display: inline-flex;
  width: ${({ num }) => {
    if (typeof num === 'undefined') {
      return `calc(100% / 6 * 2)`
    }
    return `calc(100% / 6 * ${num})`
  }};
  align-items: center;
  position: relative;

  &:after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    display: inline-block;
    width: 1px;
    height: 8px;
    background-color: ${({ theme }) => theme.bg5};
  }

  &:last-child:after {
    display: none;
  }
`
const StyledNavLink = styled.div<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme, isActive }) => (isActive ? theme.text1 : theme.text3)};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  font-size: 12px;
  flex: 1;
`

// 交易显示层
const LegendVolume = styled.div<{ top: string }>`
  display: block;
  position: absolute;
  left: 12px;
  top: ${({ top }) => top};
  color: #a3a7aa;
  font-size: 12px;
  line-height: 13px;
  z-index: 1;
  @media (max-width: 721px) {
    font-size: 10px;
  }
`
const LegendMaLine = styled.div<{ top: string; isShow: boolean }>`
  display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
  align-items: center;
  position: absolute;
  left: 12px;
  top: ${({ top }) => top};
  font-size: 12px;
  line-height: 13px;
  z-index: 1;

  .legendMaLineItem {
    margin-right: 7px;
    display: inline-flex;
    align-items: center;
  }

  @media (max-width: 721px) {
    font-size: 10px;
  }
`
const LegendTooltip = styled.div`
  position: absolute;
  left: 12px;
  top: 22px;
  background-color: ${({ theme }) => theme.bg1};
  width: 140px;
  min-height: 84px;
  border-radius: 3px;
  border: 1px solid #969696;
  padding: 8px 7px 5px 7px;
  z-index: 2;
  display: none;

  div {
    display: flex;
    align-items: flex-start;
    font-size: 10px;
    justify-content: space-between;
    padding-bottom: 3px;
  }

  span.label {
    font-size: 10px;
    color: #969696;
    color: #717173;
  }

  span.value {
    flex: 1;
    display: inline-flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    max-width: 70%;
    text-align: right;
  }

  @media (max-width: 721px) {
    top: 18px;
  }
`

export interface ChartData2Type {
  close: number | string
  high: number | string
  low: number | string
  open: number | string
  timestamp: number
  turnover: number | string
  volume: number | string
}

export interface KLineChartProps {
  data: ChartData2Type[]
  updateData: ChartData2Type[]
  timeState: string
  setting: SettingChartType
  isLoading: boolean
}

export interface CandleChartType {
  time: number | string
  open: number | string
  high: number | string
  low: number | string
  close: number | string
}

export interface MyCandleChartType extends CandleChartType {
  volume: number
}

export interface VolumeSeriesDataType {
  time: number | string
  value: number | string
  color: string
}

interface LineSeriesDataType {
  time: string | number
  value: number | string
}

function handleCallBackChartData(
  data: ChartData2Type[]
): { volumeData: VolumeSeriesDataType[]; candleData: CandleChartType[] } {
  const volumeData: VolumeSeriesDataType[] = [],
    candleData: CandleChartType[] = []
  for (const item of data) {
    volumeData.push({
      time: item.timestamp / 1000,
      value: item.volume,
      color: new BigNumber(item.close).minus(item.open).gte(new BigNumber(0))
        ? 'rgba(0, 150, 136, 0.4)'
        : 'rgba(255,82,82, 0.4)'
    })
    candleData.push({
      time: item.timestamp / 1000,
      open: item.open,
      high: item.high,
      low: item.low,
      close: item.close
    })
  }
  return {
    volumeData,
    candleData
  }
}

export default function KLineChart({ data, updateData, timeState, setting, isLoading }: KLineChartProps) {
  const [darkMode] = useDarkModeManager()
  const { t } = useTranslation()

  const [container, setContainer] = useState(false)
  const handleRef = useCallback(ref => setContainer(ref), [])
  const ParentChart = useRef<any>(null)
  const [klineData, setKlineData] = useState<{
    candleSeries: CandleChartType[]
    volumeSeries: VolumeSeriesDataType[]
    rsiSeries: LineSeriesDataType[]
    ma5Series: LineSeriesDataType[]
    ma10Series: LineSeriesDataType[]
    ma30Series: LineSeriesDataType[]
    volMa5Series: LineSeriesDataType[]
    volMa10Series: LineSeriesDataType[]
    bollUpperSeries: LineSeriesDataType[]
    bollMiddleSeries: LineSeriesDataType[]
    bollLowerSeries: LineSeriesDataType[]
    KDJKLineSeries: LineSeriesDataType[]
    KDJDLineSeries: LineSeriesDataType[]
    KDJJLineSeries: LineSeriesDataType[]
    WRKlineSeries: LineSeriesDataType[]
    MACDlineSeries: VolumeSeriesDataType[]
    DIFlineSeries: LineSeriesDataType[]
    DEAlineSeries: LineSeriesDataType[]
  }>({
    // 蜡烛 图
    candleSeries: [],
    // 交易量
    volumeSeries: [],
    // rsi 系列
    rsiSeries: [],
    // MA 蜡烛图上的 直线图
    ma5Series: [],
    ma10Series: [],
    ma30Series: [],
    //   交易量 上的MA线
    volMa5Series: [],
    volMa10Series: [],
    //   Boll
    bollUpperSeries: [],
    bollMiddleSeries: [],
    bollLowerSeries: [],
    //   kdj
    KDJKLineSeries: [],
    KDJDLineSeries: [],
    KDJJLineSeries: [],
    // WR
    WRKlineSeries: [],
    //   MACD  DIF DEA
    MACDlineSeries: [],
    DIFlineSeries: [],
    DEAlineSeries: []
  })
  // 蜡烛图 单独数据
  const candleDataRef = useRef<CandleChartType[]>([])
  // 交易量 缓存数据
  const volumeDataRef = useRef<VolumeSeriesDataType[]>([])
  //  蜡烛图
  const candleSeries = useRef<any>(null)
  //  交易量
  const volumeSeries = useRef<any>(null)
  //  RSI
  const RsiLineSeries = useRef<any>(null)
  const [RSISetting, setRSISetting] = useState<{ min: string | number; max: string | number }>({
    min: '0',
    max: '100'
  })
  // 蜡烛图上的 MA线
  const ma5LineSeries = useRef<any>(null)
  const ma10LineSeriesRef = useRef<any>(null)
  const ma30LineSeriesRef = useRef<any>(null)
  // 交易量 上的 MA线
  const volMa5LineSeries = useRef<any>(null)
  const volMa10LineSeriesRef = useRef<any>(null)
  // BOLL 线 Ref
  const bollUpperSeries = useRef<any>(null)
  const bollMiddleSeries = useRef<any>(null)
  const bollLowerSeries = useRef<any>(null)
  // kdj 三线
  const KDJKLineSeries = useRef<any>(null)
  const KDJDLineSeries = useRef<any>(null)
  const KDJJLineSeries = useRef<any>(null)
  const [KDJKSetting, setKDJKSetting] = useState<{ min: string | number; max: string | number }>({
    min: '0',
    max: '100'
  })
  const [KDJDSetting, setKDJDSetting] = useState<{ min: string | number; max: string | number }>({
    min: '0',
    max: '100'
  })
  const [KDJJSetting, setKDJJSetting] = useState<{ min: string | number; max: string | number }>({
    min: '0',
    max: '100'
  })
  // WR
  const WRLineSeries = useRef<any>(null)
  const [WRSetting, setWRSetting] = useState<{ min: string | number; max: string | number }>({
    min: '0',
    max: '100'
  })
  // MACD
  const MACDlineSeries = useRef<any>(null)
  const DIFlineSeries = useRef<any>(null)
  const DEAlineSeries = useRef<any>(null)
  const [MACDSetting, setMACDSetting] = useState<{ min: string | number; max: string | number }>({
    min: '0',
    max: '100'
  })
  const [params, setParams] = useState<{ main: string; sub: string }>({
    main: 'MA',
    sub: ''
  })
  const [rangeMaxVolRef, setRangeMaxVolRef] = useState<string | number | null>(null)
  const paramsRef = useRef<{ main: string; sub: string }>(params)
  // 指标 显示 节点
  const LegendMaLineRef = useRef<any>(null)
  const LegendVolLineRef = useRef<any>(null)
  const LegendSubRef = useRef<any>(null)
  const LegendTooltipRef = useRef<any>(null)
  const subscribeCrosshairMoveFunction = useCallback(
    function (param: MouseEventParams, width: number) {
      try {
        if (param.point === undefined || !param.time) {
          LegendTooltipRef.current.style.display = 'none'
          return
        }
        const volumeSeriesData = param.seriesData.get(volumeSeries.current) as { value: number }
        const volPrice =
          volumeSeriesData && volumeSeriesData.value
            ? bigNumberTransform(volumeSeriesData.value, { decimalPlaces: 2 })
            : '∞'
        LegendTooltipRef.current.style.display = 'block'
        const currentData = param.seriesData.get(candleSeries.current) as CandleChartType
        if (currentData && Object.keys(currentData).length) {
          const volume = bigNumberTransform(
            new BigNumber(currentData.close).minus(new BigNumber(currentData.open)).toString(),
            {
              decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
            }
          )
          const volumePercent = new BigNumber(currentData.close)
            .minus(new BigNumber(currentData.open))
            .dividedBy(new BigNumber(currentData.open))
            .multipliedBy(100)
            .decimalPlaces(2, BigNumber.ROUND_DOWN)
            .toString()
          LegendTooltipRef.current.innerHTML = `<div><span class="label">${t(
            '时间'
          )}</span><span class="value">${formTimeStamp2DateTime3(
            currentData.time
          )}</span></div><div><span class="label">${t('开盘')}</span><span class="value">${bigNumberTransform(
            currentData.open,
            {
              decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
            }
          )}</span></div><div><span class="label">${t('最高')}</span><span class="value">${bigNumberTransform(
            currentData.high,
            {
              decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
            }
          )}</span></div><div><span class="label">${t('最低')}</span><span class="value">${bigNumberTransform(
            currentData.low,
            {
              decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
            }
          )}</span></div><div><span class="label">${t('收盘')}</span><span class="value">${bigNumberTransform(
            currentData.close,
            {
              decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
            }
          )}</span></div><div><span class="label">${t('涨跌额')}</span><span class="value" style="color:${new BigNumber(currentData.close).minus(currentData.open).gte(new BigNumber(0)) ? '#5bb89b' : '#d36973'
            }">${volume}</span></div> 
            <div><span class="label">${t('涨跌幅')}</span><span class="value" style="color:${new BigNumber(currentData.close).minus(currentData.open).gte(new BigNumber(0)) ? '#5bb89b' : '#d36973'
            }">${volumePercent}%</span></div> 
            <div><span class="label">${t('成交量')}</span><span class="value">${volPrice}</span></div> 
          `
          if (param.point.x >= width / 2) {
            LegendTooltipRef.current.style.left = '10px'
            LegendTooltipRef.current.style.right = 'initial'
          } else {
            LegendTooltipRef.current.style.right = '10px'
            LegendTooltipRef.current.style.left = 'initial'
          }
        }

        LegendVolLineRef.current.innerHTML = `VOL:${volPrice}`
        if (paramsRef.current.main === 'MA') {
          const ma5LineSeriesData = param.seriesData.get(ma5LineSeries.current) as { value: number }
          const ma5Price =
            ma5LineSeriesData && ma5LineSeriesData.value
              ? bigNumberTransform(ma5LineSeriesData.value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'

          const ma10LineSeriesData = param.seriesData.get(ma10LineSeriesRef.current) as { value: number }
          const ma10Price =
            ma10LineSeriesData && ma10LineSeriesData.value
              ? bigNumberTransform(ma10LineSeriesData.value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'

          const ma30LineSeriesData = param.seriesData.get(ma30LineSeriesRef.current) as { value: number }
          const ma30Price =
            ma30LineSeriesData && ma30LineSeriesData.value
              ? bigNumberTransform(ma30LineSeriesData.value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'
          LegendMaLineRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#f1b500">MA5:${ma5Price}</div><div class="legendMaLineItem" style="color:#fe549a" >MA10:${ma10Price}</div><div class="legendMaLineItem" style="color:#0279d3" >MA30:${ma30Price}</div>`
        }
        if (paramsRef.current.main === 'BOLL') {
          const bollMiddleSeriesData = param.seriesData.get(bollMiddleSeries.current) as { value: number }
          const middlePrice =
            bollMiddleSeriesData && bollMiddleSeriesData.value
              ? bigNumberTransform(bollMiddleSeriesData.value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'

          const bollUpperSeriesData = param.seriesData.get(bollUpperSeries.current) as { value: number }
          const bollUpperPrice =
            bollUpperSeriesData && bollUpperSeriesData.value
              ? bigNumberTransform(bollUpperSeriesData.value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'

          const bollLowerSeriesData = param.seriesData.get(bollLowerSeries.current) as { value: number }
          const bollLowerPrice =
            bollLowerSeriesData && bollLowerSeriesData.value
              ? bigNumberTransform(bollLowerSeriesData.value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'
          LegendMaLineRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#fe549a" >UP:${bollUpperPrice}</div><div class="legendMaLineItem" style="color:#f1b500">BOLL:${middlePrice}</div><div class="legendMaLineItem" style="color:#0279d3" >LB:${bollLowerPrice}</div>`
        }
        if (paramsRef.current.sub === 'MACD') {
          const macdSeriesData = param.seriesData.get(MACDlineSeries.current) as { value: number }
          const macdSeriesPrice =
            macdSeriesData && macdSeriesData.value
              ? bigNumberTransform(macdSeriesData.value, { decimalPlaces: setting.decimals < 6 ? setting.decimals : 6 })
              : '∞'

          const DIFlineSeriesData = param.seriesData.get(DIFlineSeries.current) as { value: number }
          const DIFlineSeriesPrice =
            DIFlineSeriesData && DIFlineSeriesData.value
              ? bigNumberTransform(DIFlineSeriesData.value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'

          const DEAlineSeriesData = param.seriesData.get(DEAlineSeries.current) as { value: number }
          const DEAlineSeriesPrice =
            DEAlineSeriesData && DEAlineSeriesData.value
              ? bigNumberTransform(DEAlineSeriesData.value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#a3a7aa">MACD:${macdSeriesPrice}</div><div class="legendMaLineItem" style="color:#bab595">DIF:${DIFlineSeriesPrice}</div><div class="legendMaLineItem" style="color:#fe549a" >DEA:${DEAlineSeriesPrice}</div>`
        }
        if (paramsRef.current.sub === 'KDJ') {
          const KDJKLineSeriesData = param.seriesData.get(KDJKLineSeries.current) as { value: number }
          const KDJKPrice =
            KDJKLineSeriesData && KDJKLineSeriesData.value
              ? bigNumberTransform(KDJKLineSeriesData.value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'

          const KDJDLineSeriesData = param.seriesData.get(KDJDLineSeries.current) as { value: number }
          const KDJDPrice =
            KDJDLineSeriesData && KDJDLineSeriesData.value
              ? bigNumberTransform(KDJDLineSeriesData.value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'

          const KDJJLineSeriesData = param.seriesData.get(KDJJLineSeries.current) as { value: number }
          const KDJJPrice =
            KDJJLineSeriesData && KDJJLineSeriesData.value
              ? bigNumberTransform(KDJJLineSeriesData.value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#a3a7aa">KDJ(14,1,3)</div><div class="legendMaLineItem" style="color:#bab595">K:${KDJKPrice}</div><div class="legendMaLineItem" style="color:#fe549a" >D:${KDJDPrice}</div><div class="legendMaLineItem" style="color:#0279d3" >J:${KDJJPrice}</div>
        `
        }

        if (paramsRef.current.sub === 'RSI') {
          const RsiLineSeriesData = param.seriesData.get(RsiLineSeries.current) as { value: number }
          const rsiLineSeriesPrice =
            RsiLineSeriesData && RsiLineSeriesData.value
              ? bigNumberTransform(RsiLineSeriesData.value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#c3b17f">RSI(14):${rsiLineSeriesPrice}</div>`
        }
        if (paramsRef.current.sub === 'WR') {
          const WrLineSeriesData = param.seriesData.get(WRLineSeries.current) as { value: number }
          const wrLineSeriesPrice =
            WrLineSeriesData && WrLineSeriesData.value
              ? bigNumberTransform(WrLineSeriesData.value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#c3b17f">WR(14):${wrLineSeriesPrice}</div>
          `
        }
      } catch (e) {
        console.log(e)
      }
    },
    [t, setting]
  )

  const onSubscribeVisibleLogicalRangeChange = useCallback(
    ({ from, to }: { from: number; to: number }, width: number) => {
      if (!candleDataRef.current.length) {
        return
      }
      const { maxHigh, maxHighTime, minLow, minLowTime } = filterKlineDataToHL(candleDataRef.current, from, to)
      const { rangeMaxVol } = filterKlineDataToVol(volumeDataRef.current, from, to)
      if (!maxHigh) {
        return
      }
      setRangeMaxVolRef(rangeMaxVol && rangeMaxVol!.value)

      const upPosition = ParentChart.current && ParentChart.current.timeScale().timeToCoordinate(maxHighTime)
      const downPosition = ParentChart.current && ParentChart.current.timeScale().timeToCoordinate(minLowTime)
      const markersData: SeriesMarker<Time>[] = [
        {
          time: maxHighTime as Time,
          position: 'aboveBar',
          shape: 'arrowDown',
          color: darkMode ? '#a2a2a4' : '#545452',
          id: 'up',
          text:
            (upPosition < width / 5 ? `               ` : '') +
            bigNumberTransform(maxHigh, { decimalPlaces: setting.decimals < 6 ? setting.decimals : 6 }) +
            (upPosition > (width / 5) * 4 ? `               ` : '')
        },
        {
          time: minLowTime as Time,
          position: 'belowBar',
          shape: 'arrowUp',
          color: darkMode ? '#a2a2a4' : '#545452',
          id: 'down',
          text:
            (downPosition < width / 5 ? `               ` : '') +
            bigNumberTransform(minLow!, { decimalPlaces: setting.decimals < 6 ? setting.decimals : 6 }) +
            (downPosition > (width / 5) * 4 ? `               ` : '')
        }
      ]
      candleSeries.current.setMarkers(markersData)
    },
    [candleSeries, candleDataRef, darkMode, setting]
  )

  useEffect(() => {
    if (!data.length || !setting.high.length) {
      return
    }
    try {
      // 交易量
      const {
        volumeData,
        candleData
      }: {
        volumeData: VolumeSeriesDataType[]
        candleData: CandleChartType[]
      } = handleCallBackChartData(data)
      // rsi 数据
      const rsiSeriesData = calculateRSi(candleData, 14, setting.decimals)
      const { max: rsiMaxValue, min: rsiMinValue } = findMaxAndMinValues(rsiSeriesData)
      setRSISetting({
        min: rsiMinValue,
        max: rsiMaxValue
      })
      // 计算 MA 5 10
      const candiMa5Value = calculateSMA(candleData, 5)
      const candiMa10Value = calculateSMA(candleData, 10)
      const candiMa30Value = calculateSMA(candleData, 30)
      // 交易量上的 MA线
      const volMa5Value = calculateSMA(volumeData, 5, true)
      const volMa10Value = calculateSMA(volumeData, 10, true)
      // BOLL 线 middle, upper, lower
      const { middle, upper, lower } = calculateBoll(candleData)
      // 计算 jdk线
      const { k, d, j } = calculateKDJ(candleData, 14, 1, 3)
      const { max: kMaxValue, min: kMinValue } = findMaxAndMinValues(k)
      const { max: dMaxValue, min: dMinValue } = findMaxAndMinValues(d)
      const { max: jMaxValue, min: jMinValue } = findMaxAndMinValues(j)
      setKDJKSetting({
        min: kMinValue,
        max: kMaxValue
      })
      setKDJDSetting({
        min: dMinValue,
        max: dMaxValue
      })
      setKDJJSetting({
        min: jMinValue,
        max: jMaxValue
      })
      // wr
      const wrData = calculateWR(candleData)
      const { max: WRMaxValue, min: WRMinValue } = findMaxAndMinValues(wrData)
      setWRSetting({
        min: WRMinValue,
        max: WRMaxValue
      })
      // macd
      const { dif, dea, macd } = calculateMACD(candleData)
      const { max: MACDMaxValue, min: MACDMinValue } = findMaxAndMinValues(dif)
      setMACDSetting({
        min: MACDMinValue,
        max: MACDMaxValue
      })
      candleDataRef.current = candleData
      volumeDataRef.current = volumeData
      setKlineData({
        // 蜡烛 图
        candleSeries: candleData,
        // 交易量
        volumeSeries: volumeData,
        // rsi 系列
        rsiSeries: rsiSeriesData,
        // MA 蜡烛图上的 直线图
        ma5Series: candiMa5Value,
        ma10Series: candiMa10Value,
        ma30Series: candiMa30Value,
        // 交易量上的 MA 线
        volMa5Series: volMa5Value,
        volMa10Series: volMa10Value,
        //   Boll
        bollUpperSeries: upper,
        bollMiddleSeries: middle,
        bollLowerSeries: lower,
        //   kdj
        KDJKLineSeries: k,
        KDJDLineSeries: d,
        KDJJLineSeries: j,
        //   WR
        WRKlineSeries: wrData,
        //   MACD DIF DEA
        MACDlineSeries: macd,
        DIFlineSeries: dif,
        DEAlineSeries: dea
      })
      //  蜡烛图 只有他们 是更新 一条数据
      candleSeries.current.setData(candleData)
      //  交易量 只有他们 是更新 一条数据
      volumeSeries.current.setData(volumeData)
      // 以下 重新计算才能 产生平均
      //  RSI
      RsiLineSeries.current.setData(rsiSeriesData)
      // 蜡烛图上的 MA线
      ma5LineSeries.current.setData(candiMa5Value)
      ma10LineSeriesRef.current.setData(candiMa10Value)
      ma30LineSeriesRef.current.setData(candiMa30Value)
      // 交易量 上的 MA线
      volMa5LineSeries.current.setData(volMa5Value)
      volMa10LineSeriesRef.current.setData(volMa10Value)
      // BOLL 线 Ref
      bollUpperSeries.current.setData(upper)
      bollMiddleSeries.current.setData(middle)
      bollLowerSeries.current.setData(lower)
      // kdj 三线
      KDJKLineSeries.current.setData(k)
      KDJDLineSeries.current.setData(d)
      KDJJLineSeries.current.setData(j)
      // WR
      WRLineSeries.current.setData(wrData)
      // MACD
      MACDlineSeries.current.setData(macd)
      DIFlineSeries.current.setData(dif)
      DEAlineSeries.current.setData(dea)
    } catch (e) {
      console.log(e)
    }

    // 夫
  }, [data, setting])
  useEffect(() => {
    if (!LegendVolLineRef.current || !klineData.volumeSeries.length || !setting.high) {
      return
    }
    try {
      //   初始化 第一个
      // 交易量
      const volPrice = bigNumberTransform(klineData.volumeSeries[klineData.volumeSeries.length - 1].value, {
        decimalPlaces: 2
      })
      LegendVolLineRef.current.innerHTML = `VOL:${volPrice}`
      // PM2
      if (params.main === 'MA') {
        const ma5Price =
          klineData.ma5Series.length > 0
            ? bigNumberTransform(klineData.ma5Series[klineData.ma5Series.length - 1].value, {
              decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
            })
            : '∞'
        const ma10Price =
          klineData.ma10Series.length > 0
            ? bigNumberTransform(klineData.ma10Series[klineData.ma10Series.length - 1].value, {
              decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
            })
            : '∞'
        const ma30Price =
          klineData.ma30Series.length > 0
            ? bigNumberTransform(klineData.ma30Series[klineData.ma30Series.length - 1].value, {
              decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
            })
            : '∞'
        LegendMaLineRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#f1b500">MA5:${ma5Price}</div><div class="legendMaLineItem" style="color:#fe549a" >MA10:${ma10Price}</div><div class="legendMaLineItem" style="color:#0279d3" >MA30:${ma30Price}</div>`
      }
      if (params.main === 'BOLL') {
        const middlePrice =
          klineData.bollMiddleSeries.length > 0
            ? bigNumberTransform(klineData.bollMiddleSeries[klineData.bollMiddleSeries.length - 1].value, {
              decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
            })
            : '∞'

        const bollUpperPrice =
          klineData.bollUpperSeries.length > 0
            ? bigNumberTransform(klineData.bollUpperSeries[klineData.bollUpperSeries.length - 1].value, {
              decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
            })
            : '∞'

        const bollLowerPrice =
          klineData.bollLowerSeries.length > 0
            ? bigNumberTransform(klineData.bollLowerSeries[klineData.bollLowerSeries.length - 1].value, {
              decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
            })
            : '∞'
        LegendMaLineRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#fe549a" >UP:${bollUpperPrice}</div><div class="legendMaLineItem" style="color:#f1b500">BOLL:${middlePrice}</div><div class="legendMaLineItem" style="color:#0279d3" >LB:${bollLowerPrice}</div>`
      }
      if (params.sub) {
        if (params.sub === 'KDJ') {
          const KDJKPrice =
            klineData.KDJKLineSeries.length > 0
              ? bigNumberTransform(klineData.KDJKLineSeries[klineData.KDJKLineSeries.length - 1].value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'

          const KDJDPrice =
            klineData.KDJKLineSeries.length > 0
              ? bigNumberTransform(klineData.KDJDLineSeries[klineData.KDJDLineSeries.length - 1].value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'

          const KDJJPrice =
            klineData.KDJKLineSeries.length > 0
              ? bigNumberTransform(klineData.KDJJLineSeries[klineData.KDJJLineSeries.length - 1].value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#a3a7aa">KDJ(14,1,3)</div><div class="legendMaLineItem" style="color:#bab595">K:${KDJKPrice}</div><div class="legendMaLineItem" style="color:#fe549a" >D:${KDJDPrice}</div><div class="legendMaLineItem" style="color:#0279d3" >J:${KDJJPrice}</div>`
        }

        if (params.sub === 'RSI') {
          const rsiLineSeriesPrice =
            klineData.rsiSeries.length > 0
              ? bigNumberTransform(klineData.rsiSeries[klineData.rsiSeries.length - 1].value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#c3b17f">RSI(14):${rsiLineSeriesPrice}</div>`
        }
        if (params.sub === 'WR') {
          const wrLineSeriesPrice =
            klineData.WRKlineSeries.length > 0
              ? bigNumberTransform(klineData.WRKlineSeries[klineData.WRKlineSeries.length - 1].value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#c3b17f">WR(14):${wrLineSeriesPrice}</div>`
        }

        if (paramsRef.current.sub === 'MACD') {
          const macdSeriesPrice =
            klineData.MACDlineSeries.length > 0
              ? bigNumberTransform(klineData.MACDlineSeries[klineData.MACDlineSeries.length - 1].value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'

          const DIFlineSeriesPrice =
            klineData.DIFlineSeries.length > 0
              ? bigNumberTransform(klineData.DIFlineSeries[klineData.DIFlineSeries.length - 1].value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'

          const DEAlineSeriesPrice =
            klineData.DEAlineSeries.length > 0
              ? bigNumberTransform(klineData.DEAlineSeries[klineData.DEAlineSeries.length - 1].value, {
                decimalPlaces: setting.decimals < 6 ? setting.decimals : 6
              })
              : '∞'
          LegendSubRef.current.innerHTML = `<div class="legendMaLineItem" style="color:#a3a7aa">MACD:${macdSeriesPrice}</div><div class="legendMaLineItem" style="color:#bab595">DIF:${DIFlineSeriesPrice}</div><div class="legendMaLineItem" style="color:#fe549a" >DEA:${DEAlineSeriesPrice}</div>`
        }
      }
    } catch (e) {
      console.log(e)
    }
  }, [klineData, params.main, params.sub, setting])
  useEffect(() => {
    if (!updateData || !updateData.length) {
      return
    }
    // 交易量
    const {
      volumeData,
      candleData
    }: {
      volumeData: VolumeSeriesDataType[]
      candleData: CandleChartType[]
    } = handleCallBackChartData(updateData)
    //  最新缓存的 数据 加1
    // 蜡烛图 单独数据
    const candleFindIndex = candleDataRef.current.findIndex(
      item => item.time === candleData[candleData.length - 1].time
    )
    if (candleFindIndex > -1) {
      const volFindIndex = volumeDataRef.current.findIndex(item => item.time === volumeData[volumeData.length - 1].time)
      candleDataRef.current[candleFindIndex] = candleData[candleData.length - 1]
      volumeDataRef.current[volFindIndex] = volumeData[volumeData.length - 1]
    } else {
      //  最新缓存的 数据 加1
      // 蜡烛图 单独数据
      candleDataRef.current.push(candleData[candleData.length - 1])
      volumeDataRef.current.push(volumeData[volumeData.length - 1])
    }
    // // rsi 数据
    const rsiSeriesData = calculateRSi(candleDataRef.current, setting.decimals)
    const { max: rsiMaxValue, min: rsiMinValue } = findMaxAndMinValues(rsiSeriesData)
    setRSISetting({
      min: rsiMinValue,
      max: rsiMaxValue
    })
    // // 计算 MA 5 10
    const candiMa5Value = calculateSMA(candleDataRef.current, 5)
    const candiMa10Value = calculateSMA(candleDataRef.current, 10)
    const candiMa30Value = calculateSMA(candleDataRef.current, 30)
    // // 交易量上的 MA线
    const volMa5Value = calculateSMA(volumeDataRef.current, 5, true)
    const volMa10Value = calculateSMA(volumeDataRef.current, 10, true)
    // // BOLL 线 middle, upper, lower
    const { middle, upper, lower } = calculateBoll(candleDataRef.current)
    // // 计算 jdk线
    const { k, d, j } = calculateKDJ(candleDataRef.current, 14, 1, 3)
    const { max: kMaxValue, min: kMinValue } = findMaxAndMinValues(k)
    const { max: dMaxValue, min: dMinValue } = findMaxAndMinValues(d)
    const { max: jMaxValue, min: jMinValue } = findMaxAndMinValues(j)
    setKDJKSetting({
      min: kMinValue,
      max: kMaxValue
    })
    setKDJDSetting({
      min: dMinValue,
      max: dMaxValue
    })
    setKDJJSetting({
      min: jMinValue,
      max: jMaxValue
    })
    // // wr
    const wrData = calculateWR(candleDataRef.current)
    const { max: WRMaxValue, min: WRMinValue } = findMaxAndMinValues(wrData)
    setWRSetting({
      min: WRMinValue,
      max: WRMaxValue
    })
    // // macd
    const { dif, dea, macd } = calculateMACD(candleDataRef.current)
    const { max: MACDMaxValue, min: MACDMinValue } = findMaxAndMinValues(dif)
    setMACDSetting({
      min: MACDMinValue,
      max: MACDMaxValue
    })
    //  蜡烛图 只有他们 是更新 一条数据
    candleSeries.current.update(candleData[candleData.length - 1])
    //  交易量 只有他们 是更新 一条数据
    volumeSeries.current.update(volumeData[volumeData.length - 1])
    // 以下 重新计算才能 产生平均
    //  RSI
    RsiLineSeries.current.setData(rsiSeriesData)
    // 蜡烛图上的 MA线
    ma5LineSeries.current.setData(candiMa5Value)
    ma10LineSeriesRef.current.setData(candiMa10Value)
    ma30LineSeriesRef.current.setData(candiMa30Value)
    // 交易量 上的 MA线
    volMa5LineSeries.current.setData(volMa5Value)
    volMa10LineSeriesRef.current.setData(volMa10Value)
    // BOLL 线 Ref
    bollUpperSeries.current.setData(upper)
    bollMiddleSeries.current.setData(middle)
    bollLowerSeries.current.setData(lower)
    // kdj 三线
    KDJKLineSeries.current.setData(k)
    KDJDLineSeries.current.setData(d)
    KDJJLineSeries.current.setData(j)
    // WR
    WRLineSeries.current.setData(wrData)
    // MACD
    MACDlineSeries.current.setData(macd)
    DIFlineSeries.current.setData(dif)
    DEAlineSeries.current.setData(dea)
  }, [updateData, klineData, setting])
  useEffect(() => {
    if (!data.length) {
      return
    }
    ParentChart.current &&
      ParentChart.current.timeScale().applyOptions({
        rightOffset: 0,
        barSpacing: isMobile ? 8 : 6
      })
  }, [data, timeState, isMobile])
  useEffect(() => {
    return () => {
      candleDataRef.current = []
      volumeDataRef.current = []
    }
  }, [])
  return (
    <KLineChartContainer>
      <div ref={handleRef}>
        {container && (
          <ChartContainer
            height={410}
            indcatemain={params.main}
            onSubscribeCrosshairMove={subscribeCrosshairMoveFunction}
            onSubscribeVisibleLogicalRangeChange={onSubscribeVisibleLogicalRangeChange}
            ref={ParentChart}
            container={container}
            overlayPriceScales={params.sub.length > 0 ? {} : {}}
          >
            {/* 蜡烛图 默认 是 right */}
            <KChartCandleSeries
              scaleMargins={
                params.sub.length > 0
                  ? {
                    top: 0.1,
                    bottom: 0.5
                  }
                  : {
                    top: 0.1,
                    bottom: 0.3
                  }
              }
              ref={candleSeries}
              priceFormat={{
                type: 'custom',
                formatter: (priceValue: BarPrice) => {
                  if (new BigNumber(priceValue).abs().lte(new BigNumber(0.0001))) {
                    const totalStr = new BigNumber(priceValue).toFixed(12).toString()
                    const zeroIndex = findFirstNonZeroIndex(totalStr)
                    if (zeroIndex < 0) {
                      return '0'
                    }
                    const ind = totalStr.substr(zeroIndex + 2, 3)
                    return `${totalStr.indexOf('-') > -1 ? '-' : ''}0.{${zeroIndex}}${ind}`
                    // return new BigNumber(priceValue).toFixed(10).toString()
                  }
                  if (new BigNumber(priceValue).abs().lte(new BigNumber(1))) {
                    const totalStr = new BigNumber(priceValue).toFixed(12).toString()
                    const zeroIndex = findFirstNonZeroIndex(totalStr)
                    if (zeroIndex < 0) {
                      return '0'
                    }
                    //  容易 出现 坐标轴 增宽  治标 不治本
                    if (setting.decimals > 6) {
                      return '0'
                    }
                    return new BigNumber(priceValue).toFixed(setting.decimals).toString()
                  }
                  return new BigNumber(priceValue).toFixed(2).toString()
                },
                minMove: new BigNumber(0.1).pow(setting.decimals).toNumber()
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  // res.priceRange.minValue = new BigNumber(setting.low).toNumber()
                  res.priceRange.minValue -= new BigNumber(0.1).pow(setting.decimals).toNumber()
                  res.priceRange.maxValue += new BigNumber(0.1).pow(setting.decimals).toNumber()
                  // res.priceRange.maxValue += new BigNumber(0.1).pow(setting.decimals).toNumber()
                }
                return res
              }}
            />
            {/*Boll Upper 线*/}
            <KChartLineSeries
              ref={bollUpperSeries}
              color={'#fe549a'}
              autoscaleInfoProvider={(original: any) => {
                return original()
              }}
              visible={params.main === 'BOLL'}
              priceScaleId={'right'}
            />
            {/*Boll Middle 线*/}
            <KChartLineSeries
              ref={bollMiddleSeries}
              color={'#f1b500'}
              autoscaleInfoProvider={(original: any) => {
                return original()
              }}
              visible={params.main === 'BOLL'}
              priceScaleId={'right'}
            />
            {/*Boll Lower 线*/}
            <KChartLineSeries
              ref={bollLowerSeries}
              color={'#0279d3'}
              autoscaleInfoProvider={(original: any) => {
                return original()
              }}
              visible={params.main === 'BOLL'}
              priceScaleId={'right'}
            />
            {/*Boll end 线*/}
            {/*MA 5 线 */}
            <KChartLineSeries
              ref={ma5LineSeries}
              color={'#f1b500'}
              autoscaleInfoProvider={(original: any) => {
                return original()
              }}
              visible={params.main === 'MA'}
              priceScaleId={'right'}
            />
            {/*color={'#fe549a'}*/}
            {/*MA 10 线 */}
            <KChartLineSeries
              visible={params.main === 'MA'}
              ref={ma10LineSeriesRef}
              color={'#fe549a'}
              autoscaleInfoProvider={(original: any) => {
                return original()
              }}
              priceScaleId={'right'}
            />
            {/*MA 30 线 */}
            <KChartLineSeries
              visible={params.main === 'MA'}
              ref={ma30LineSeriesRef}
              autoscaleInfoProvider={(original: any) => {
                return original()
              }}
              color={'#0279d3'}
              priceScaleId={'right'}
            />

            {/*交易额 */}
            {/*MA 5 线 */}
            <KChartLineSeries
              ref={volMa5LineSeries}
              color={'#f1b500'}
              autoscaleInfoProvider={(original: any) => {
                return original()
              }}
              priceScaleId={'volume'}
            />
            {/*color={'#fe549a'}*/}
            {/*MA 10 线 */}
            <KChartLineSeries
              ref={volMa10LineSeriesRef}
              color={'#fe549a'}
              autoscaleInfoProvider={(original: any) => {
                return original()
              }}
              priceScaleId={'volume'}
            />
            <KChartVolumeSeries
              ref={volumeSeries}
              priceFormat={{
                type: 'volume',
                minMove: new BigNumber(0.1).pow(setting.volDecimals).toNumber()
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  res.priceRange.minValue = 0
                  if (rangeMaxVolRef) {
                    res.priceRange.maxValue += new BigNumber(rangeMaxVolRef).toNumber()
                  }
                }
                return res
              }}
              scaleMargins={
                params.sub === ''
                  ? {
                    top: 0.75,
                    bottom: 0
                  }
                  : {
                    top: 0.55,
                    bottom: 0.24
                  }
              }
              color={'#26a69a'}
              priceScaleId={'volume'}
            />
            {/* RSI交易额 */}
            <KChartLineSeries
              ref={RsiLineSeries}
              visible={params.sub === 'RSI'}
              scaleMargins={{
                top: 0.8,
                bottom: 0
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  res.priceRange.minValue = new BigNumber(RSISetting.min).toNumber()
                  res.priceRange.maxValue = new BigNumber(RSISetting.max).toNumber()
                }
                return res
              }}
              color={'#c3b17f'}
              priceScaleId={'sub'}
            />
            {/* KDJ  K :#8d82ba  D: #bab595 J:#8aa5a0 */}
            <KChartLineSeries
              ref={KDJKLineSeries}
              visible={params.sub === 'KDJ'}
              scaleMargins={{
                top: 0.8,
                bottom: 0
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  res.priceRange.minValue = new BigNumber(KDJKSetting.min).toNumber()
                  res.priceRange.maxValue = new BigNumber(KDJKSetting.max).toNumber()
                }
                return res
              }}
              color={'#bab595'}
              priceScaleId={'sub'}
            />
            <KChartLineSeries
              ref={KDJDLineSeries}
              visible={params.sub === 'KDJ'}
              scaleMargins={{
                top: 0.8,
                bottom: 0
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  res.priceRange.minValue = new BigNumber(KDJDSetting.min).toNumber()
                  res.priceRange.maxValue = new BigNumber(KDJDSetting.max).toNumber()
                }
                return res
              }}
              color={'#8aa5a0'}
              priceScaleId={'sub'}
            />
            <KChartLineSeries
              ref={KDJJLineSeries}
              visible={params.sub === 'KDJ'}
              scaleMargins={{
                top: 0.8,
                bottom: 0
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  res.priceRange.minValue = new BigNumber(KDJJSetting.min).toNumber()
                  res.priceRange.maxValue = new BigNumber(KDJJSetting.max).toNumber()
                }
                return res
              }}
              color={'#8d82ba'}
              priceScaleId={'sub'}
            />
            {/*  WR 线*/}
            <KChartLineSeries
              ref={WRLineSeries}
              visible={params.sub === 'WR'}
              scaleMargins={{
                top: 0.8,
                bottom: 0
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  res.priceRange.minValue = new BigNumber(WRSetting.min).toNumber()
                  res.priceRange.maxValue = new BigNumber(WRSetting.max).toNumber()
                }
                return res
              }}
              color={'#c3b17f'}
              priceScaleId={'sub'}
            />
            {/*  macd 线*/}
            <KChartMACDSeries
              visible={params.sub === 'MACD'}
              ref={MACDlineSeries}
              scaleMargins={{
                top: 0.85,
                bottom: 0
              }}
              color={'#26a69a'}
              priceScaleId={'sub'}
            />
            {/*  DIF 线*/}
            <KChartLineSeries
              ref={DIFlineSeries}
              visible={params.sub === 'MACD'}
              scaleMargins={{
                top: 0.85,
                bottom: 0
              }}
              autoscaleInfoProvider={(original: any) => {
                const res = original()
                if (res !== null) {
                  res.priceRange.minValue = new BigNumber(MACDSetting.min).toNumber()
                }
                return res
              }}
              color={'#bfb88c'}
              priceScaleId={'sub'}
            />
            {/*  DEA 线*/}
            <KChartLineSeries
              ref={DEAlineSeries}
              visible={params.sub === 'MACD'}
              scaleMargins={{
                top: 0.85,
                bottom: 0
              }}
              color={'#84a89e'}
              priceScaleId={'sub'}
            />
          </ChartContainer>
        )}
      </div>
      <LegendMaLine isShow={true} ref={LegendMaLineRef} top={'0px'}></LegendMaLine>
      <LegendVolume
        ref={LegendVolLineRef}
        top={params.sub.length > 0 ? 410 * 0.58 - 30 + 'px' : 410 * 0.75 - 30 + 'px'}
      ></LegendVolume>
      <LegendMaLine ref={LegendSubRef} isShow={params.sub.length > 0} top={410 * 0.79 - 30 + 'px'}></LegendMaLine>
      <LegendTooltip ref={LegendTooltipRef}></LegendTooltip>
      <KlineIndicatorContainer>
        <KlineIndicatorItem>
          {mainIndicator.map(item => (
            <StyledNavLink
              isActive={params.main === item}
              onClick={() => {
                if (params.main === item) {
                  paramsRef.current = { ...params, main: '' }
                  setParams({ ...params, main: '' })
                  return
                }
                paramsRef.current = { ...params, main: item }
                setParams({ ...params, main: item })
              }}
              key={item}
            >
              {item}
            </StyledNavLink>
          ))}
        </KlineIndicatorItem>
        <KlineIndicatorItem num={subIndicator.length}>
          {subIndicator.map(item => (
            <StyledNavLink
              isActive={params.sub.includes(item)}
              key={item}
              onClick={() => {
                if (params.sub === item) {
                  paramsRef.current = { ...params, sub: '' }
                  setParams({ ...params, sub: '' })
                  return
                }
                paramsRef.current = { ...params, sub: item }
                setParams({ ...params, sub: item })
              }}
            >
              {item}
            </StyledNavLink>
          ))}
        </KlineIndicatorItem>
      </KlineIndicatorContainer>
      <KLineChartMask isShow={!isLoading}>
        <Loader size={'32px'}></Loader>
      </KLineChartMask>
    </KLineChartContainer>
  )
}
