import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef } from 'react'
import { createChart, IChartApi, Time, MouseEventParams, TimeRange } from 'lightweight-charts'

import { Context } from './KlineChartParent'
import { formTimeStamp2DateTime3 } from '../../utils/common'
import { useDarkModeManager } from '../../state/user/hooks'
import { themesData } from './kLineChartTheme'
const ChartContainer = forwardRef((props: any, ref) => {
  const [darkMode] = useDarkModeManager()
  const { children, height, overlayPriceScales, watermark, container, layout, ...rest } = props
  const chartApiRef = useRef<{ _api?: IChartApi; api: () => IChartApi; free: () => void }>({
    api() {
      if (!this._api) {
        this._api = createChart(container, {
          watermark: watermark || {},
          width: container.clientWidth,
          height: height ? height : 350,
          localization: {
            locale: 'en',
            dateFormat: 'yy/MM/dd',
            timeFormatter: (time: Time) => {
              return formTimeStamp2DateTime3(time as number)
            }
          },
          overlayPriceScales: true,
          handleScale: {
            mouseWheel: true,
            pinch: true,
            axisDoubleClickReset: true,
            axisPressedMouseMove: false
          },
          kineticScroll: {
            mouse: false,
            touch: true
          },
          rightPriceScale: {
            visible: true,
            ticksVisible: true,
            borderVisible: false,
            entireTextOnly: false,
            autoScale: true
          },
          handleScroll: {
            vertTouchDrag: false
          },
          timeScale: {
            rightBarStaysOnScroll: true,
            lockVisibleTimeRangeOnResize: true,
            secondsVisible: true,
            borderVisible: false,
            timeVisible: true,
            ticksVisible: false
          },
          ...rest
        })
        this._api.timeScale().applyOptions({
          minBarSpacing: 1,
          barSpacing: 8,
          fixLeftEdge: false,
          ticksVisible: false,
          rightOffset: 2
        })
      }
      return this._api
    },
    free() {
      this._api && this._api.remove()
    }
  })

  useLayoutEffect(() => {
    const currentRef = chartApiRef.current
    const chart = currentRef.api()
    const handleResize = () => {
      chart.applyOptions({
        ...rest,
        width: container.clientWidth
      })
    }
    const handleSubscribeCrosshairMove = (param: MouseEventParams) => {
      const width = chart.timeScale().width()
      props.onSubscribeCrosshairMove(param, width)
    }
    chart.subscribeCrosshairMove(handleSubscribeCrosshairMove)

    //
    const handleSubscribeVisibleLogicalRangeChange = function(timeRange: TimeRange | null) {
      if (!timeRange) {
        return
      }
      const width = chart.timeScale().width()
      props.onSubscribeVisibleLogicalRangeChange(timeRange, width)
    }
    chart.timeScale().subscribeVisibleTimeRangeChange(handleSubscribeVisibleLogicalRangeChange)

    window.addEventListener('resize', handleResize)
    return () => {
      chart && chart.timeScale().unsubscribeVisibleTimeRangeChange(handleSubscribeVisibleLogicalRangeChange)
      chart && chart.unsubscribeCrosshairMove(handleSubscribeCrosshairMove)
      window.removeEventListener('resize', handleResize)
      chart && chart.remove()
    }
  }, [])

  useEffect(() => {
    const currentRef = chartApiRef.current
    currentRef.api().applyOptions({
      ...themesData[darkMode ? 'Dark' : 'Light'].chart
    })
  }, [darkMode])

  useEffect(() => {
    if (!overlayPriceScales) {
      return
    }
    const currentRef = chartApiRef.current
    currentRef.api().applyOptions({
      overlayPriceScales: overlayPriceScales
    })
  }, [overlayPriceScales])

  useLayoutEffect(() => {
    const currentRef = chartApiRef.current
    currentRef.api().applyOptions(rest)
  }, [])

  useImperativeHandle(ref, () => chartApiRef.current.api(), [])

  useEffect(() => {
    const currentRef = chartApiRef.current
    currentRef.api().applyOptions({ layout })
  }, [layout])

  return <Context.Provider value={chartApiRef.current}>{children}</Context.Provider>
})

export default ChartContainer
