import React from 'react'
import styled from 'styled-components'
import useCopyClipboard from '../../hooks/useCopyClipboard'

import { LinkStyledButton } from '../../theme'
import { CheckCircle, Copy } from 'react-feather'
import { useTranslation } from 'react-i18next'

const CopyIcon = styled(LinkStyledButton)<{ isShowBox?: boolean }>`
  color: ${({ theme }) => theme.text3};
  flex-shrink: 0;
  display: flex;
  text-decoration: none;
  font-size: 0.825rem;
  padding: ${({ isShowBox }) => (!isShowBox ? 'inherit' : '0px')};
  :hover,
  :active,
  :focus {
    text-decoration: none;
    color: ${({ theme }) => theme.text2};
  }
`
const TransactionStatusText = styled.span<{ isShowBox?: boolean; isDisplay?: boolean }>`
  margin-left: ${({ isShowBox }) => (!isShowBox ? '0.25rem' : '0px')};
  font-size: 0.825rem;
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
  display: ${({ isDisplay }) => (isDisplay ? 'none' : 'inline-block')};
`

export default function CopyHelper(props: {
  toCopy: string
  isShowBox?: boolean
  size?: string
  hideText?: boolean
  children?: React.ReactNode
}) {
  const { t } = useTranslation()
  const [isCopied, setCopied] = useCopyClipboard()

  return (
    <CopyIcon isShowBox={props.isShowBox} onClick={() => setCopied(props.toCopy)}>
      {isCopied ? (
        <TransactionStatusText>
          <CheckCircle size={props.size || '16'} />
          <TransactionStatusText isShowBox={props.isShowBox} isDisplay={props.hideText}>
            {t('Copied')}
          </TransactionStatusText>
        </TransactionStatusText>
      ) : (
        <TransactionStatusText isShowBox={props.isShowBox}>
          <Copy size={props.size || '16'} />
        </TransactionStatusText>
      )}
      {isCopied ? '' : props.children}
    </CopyIcon>
  )
}
