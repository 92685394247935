import { useCallback, useEffect, useRef } from 'react'

export default function useWebSocket({
  wsUrl,
  handleHBOperation,
  onMessage
}: {
  wsUrl: string
  handleHBOperation?: Function
  onMessage: Function
}) {
  const socket = useRef<WebSocket | null>(null)
  const lockReconnect = useRef<boolean>(false)
  const socketTimer = useRef<any>(null)
  const socketHeartTimer = useRef<any>(null)
  const socketHeartRestartTimer = useRef<any>(null)
  const isRestartInit = useRef<boolean>(false)

  const handleWSMessage = function(event: { data: any }) {
    if (isRestartInit.current && socketHeartRestartTimer.current) {
      isRestartInit.current = false
    }
    socketHeartRestartTimer.current && clearTimeout(socketHeartRestartTimer.current)
    const { data } = event
    if (data === 'pong') {
      return
    }
    let resultData = data
    if (typeof data === 'string') {
      resultData = JSON.parse(data)
    }
    if (!Boolean(resultData.type && resultData.code == '1')) {
      return
    }
    onMessage && onMessage(resultData)
  }
  const wsHeartBeat = useCallback(async () => {
    handleHBOperation && (await handleHBOperation())
    socketHeartTimer.current && clearInterval(socketHeartTimer.current)
    socketHeartRestartTimer.current && clearTimeout(socketHeartRestartTimer.current)
    socketHeartTimer.current = setInterval(() => {
      if (socket.current?.readyState === 1) {
        socket.current && socket.current!.send('ping')
      }
      socketHeartRestartTimer.current = setTimeout(() => {
        socketHeartRestartTimer.current && clearTimeout(socketHeartRestartTimer.current)
        if (socket.current?.readyState === 3) {
          socketHeartTimer.current && clearInterval(socketHeartTimer.current)
          socket.current.close()
          socket.current = null
          isRestartInit.current = true
        }
      }, 5000)
    }, 7000)
  }, [socket.current])
  const initWebSocket = useCallback(() => {
    if (!(!!window.WebSocket && window.WebSocket.prototype.send)) {
      alert('您的浏览器不支持Websocket通信协议，请使用Chrome或者Firefox浏览器！')
      return
    }
    socket.current = new WebSocket(wsUrl)
    socket.current.onopen = wsHeartBeat
    socket.current.onmessage = handleWSMessage
    socket.current!.onerror = err => {
      if (socket.current) {
        socket.current.close()
        socket.current = null
      }
      console.log('================ websocket error ================\n', err)
    }
    socket.current!.onclose = () => {
      if (!lockReconnect.current) {
        socketTimer.current && clearTimeout(socketTimer.current)
        socketTimer.current = setTimeout(() => {
          socketTimer.current && clearTimeout(socketTimer.current)
          initWebSocket()
        }, 3000)
      }
      console.log('================ websocket close ================')
    }
  }, [wsUrl, wsHeartBeat])
  //  主动关闭ws
  const disconnectedWS = function() {
    if (socket.current) {
      lockReconnect.current = true
      socket.current.close()
      socket.current = null
    }
    socketTimer.current && clearTimeout(socketTimer.current)
    socketHeartRestartTimer.current && clearTimeout(socketHeartRestartTimer.current)
    socketHeartTimer.current && clearInterval(socketHeartTimer.current)
  }
  useEffect(() => {
    return () => {
      if (socket.current) {
        lockReconnect.current = true
        socket.current.close()
        socket.current = null
      }
      socketTimer.current && clearTimeout(socketTimer.current)
      socketHeartRestartTimer.current && clearTimeout(socketHeartRestartTimer.current)
      socketHeartTimer.current && clearInterval(socketHeartTimer.current)
    }
  }, [])
  return {
    socket,
    disconnectedWS,
    initWebSocket
  }
}
