import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { parse } from 'qs'
import { AppDispatch } from '../state'
import { updateUserDarkMode, updateUserLang } from '../state/user/actions'
import i18next from 'i18next'

export default function DarkModeQueryParamReader({ location: { search } }: RouteComponentProps): null {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (!search) return
    if (search.length < 2) return

    const parsed = parse(search, {
      parseArrays: false,
      ignoreQueryPrefix: true
    })

    const theme = parsed.theme
    const lang = parsed.lang
    // 添加 语言 通过 url 设置 ?lang=en
    if (typeof lang === 'string') {
      if (lang.toLowerCase() === 'en') {
        dispatch(updateUserLang({ userLang: 'en' }))
        i18next.changeLanguage('en')
      } else if (lang.toLowerCase() === 'zh') {
        dispatch(updateUserLang({ userLang: 'zh' }))
        i18next.changeLanguage('zh')
      }
    }

    if (typeof theme === 'string') {
      if (theme.toLowerCase() === 'light') {
        dispatch(updateUserDarkMode({ userDarkMode: false }))
      } else if (theme.toLowerCase() === 'dark') {
        dispatch(updateUserDarkMode({ userDarkMode: true }))
      }
    }
  }, [dispatch, search])

  return null
}
