import React, { forwardRef, useContext, useEffect, useImperativeHandle, useLayoutEffect, useRef } from 'react'
import { Context } from './KlineChartParent'
import { IChartApi, ISeriesApi } from 'lightweight-charts'
const KChartLineSeries = forwardRef((props: any, ref) => {
  const parent = useContext(Context) as { _api: IChartApi; api: () => IChartApi; free: () => void }
  const context = useRef<{
    _api?: ISeriesApi<'Line'>
    api: () => ISeriesApi<'Line'>
    free: () => void
    _parent?: IChartApi
  }>({
    api() {
      if (!this._api) {
        const { children, data, color, priceFormat, autoscaleInfoProvider, ...rest } = props
        this._api = parent.api().addLineSeries({
          color,
          lineWidth: 1,
          lastValueVisible: false,
          priceLineVisible: false,
          autoscaleInfoProvider:
            autoscaleInfoProvider && typeof autoscaleInfoProvider === 'function'
              ? autoscaleInfoProvider
              : () => {
                  return null
                },
          ...rest
        })
      }
      return this._api
    },
    free() {
      if (this._api) {
        parent && parent.free()
      }
    }
  })
  useLayoutEffect(() => {
    const currentRef = context.current
    const { children, data, ...rest } = props
    currentRef.api().applyOptions(rest)
  })
  useEffect(() => {
    if (!props.scaleMargins) {
      return
    }
    const currentRef = context.current
    currentRef
      .api()
      .priceScale()
      .applyOptions({
        scaleMargins: props.scaleMargins
      })
  }, [props.scaleMargins])
  useEffect(() => {
    if (props.visible === undefined) {
      return
    }
    const currentRef = context.current
    currentRef.api().applyOptions({
      visible: props.visible
    })
  }, [props.visible])

  useEffect(() => {
    if (!props.priceScaleId) {
      return
    }
    const currentRef = context.current
    currentRef.api().applyOptions({
      priceScaleId: props.priceScaleId
    })
  }, [props.priceScaleId])
  useImperativeHandle(ref, () => context.current.api(), [])
  return <Context.Provider value={context.current}>{props.children}</Context.Provider>
})
export default KChartLineSeries
