import { CandleChartType, ChartData2Type, VolumeSeriesDataType } from './index'
import { BigNumber } from 'bignumber.js'

const avg = function(data: VolumeSeriesDataType[] | CandleChartType[], isVolume: boolean) {
  let sum = new BigNumber(0)
  for (let i = 0; i < data.length; i++) {
    // @ts-ignore
    sum = sum.plus(Boolean(isVolume) ? new BigNumber(data[i].value) : new BigNumber(data[i].close))
  }
  return sum.dividedBy(data.length).toNumber()
}

export function calculateSMA(
  data: VolumeSeriesDataType[] | CandleChartType[],
  count: number,
  isVolume = false
): { time: string | number; value: number }[] {
  const result = []
  for (let i = count - 1, len = data.length; i < len; i++) {
    const val = avg(data.slice(i - count + 1, i), isVolume)
    result.push({ time: data[i].time, value: val })
  }
  return result
}

export function calculateRSi(data: CandleChartType[], count = 14, decimals = 2) {
  const rsiValues: { time: number | string; value: string }[] = []
  const closeArray: { time: number | string; value: string }[] = []

  for (let i = 0; i < data.length; i++) {
    const close = data[i].close // 获取第i个元素的close
    closeArray.push({ time: data[i].time, value: close as string })
  }
  for (let j = 0; j < closeArray.length; j++) {
    if (j < count) {
      continue
    }

    let sumGain = new BigNumber(0) // 上涨累积值
    let sumLoss = new BigNumber(0) // 下跌累积值

    for (let k = j - count + 1; k <= j; k++) {
      const diff = new BigNumber(closeArray[k].value).minus(new BigNumber(closeArray[k - 1].value))
      if (diff.gt(new BigNumber(0))) {
        sumGain = new BigNumber(sumGain).plus(new BigNumber(diff))
      } else if (diff.lt(0)) {
        sumLoss = new BigNumber(sumLoss).minus(new BigNumber(diff))
      }
    }
    const avgGain = sumGain.dividedBy(new BigNumber(count)) // 平均上涨值
    const avgLoss = sumLoss.dividedBy(new BigNumber(count)) // 平均下跌值
    const rs = avgGain.dividedBy(avgLoss) // 相对强弱比
    const rsi = new BigNumber(100).minus(new BigNumber(100).dividedBy(new BigNumber(1).plus(rs))).toFixed(decimals)
    // RSI指标
    rsiValues.push({ time: closeArray[j].time, value: rsi })
  }
  return rsiValues
}

function calculateStandardDeviation(data: BigNumber[], average: BigNumber): BigNumber {
  const deviations = data.map(price => price.minus(average).pow(2))
  const sumOfDeviations = deviations.reduce((sum, deviation) => sum.plus(deviation), new BigNumber(0))
  const variance = sumOfDeviations.dividedBy(data.length)
  const standardDeviation = variance.squareRoot()

  return standardDeviation
}

export function calculateBoll(data: CandleChartType[], period = 20, deviations = 2) {
  const upperBoll: { time: number | string; value: number | string }[] = []
  const middleBoll: { time: number | string; value: number | string }[] = []
  const lowerBoll: { time: number | string; value: number | string }[] = []

  for (let i = period - 1; i < data.length; i++) {
    const prices = data.slice(i - period + 1, i + 1).map(candle => new BigNumber(candle.close))
    const average = prices.reduce((sum, price) => sum.plus(price), new BigNumber(0)).dividedBy(period)
    const standardDeviation = calculateStandardDeviation(prices, average)
    const upper = average.plus(standardDeviation.times(deviations)).toString()
    const lower = average.minus(standardDeviation.times(deviations)).toString()
    upperBoll.push({ time: data[i].time, value: upper })
    middleBoll.push({ time: data[i].time, value: average.toString() })
    lowerBoll.push({ time: data[i].time, value: lower })
  }

  return {
    upper: upperBoll,
    middle: middleBoll,
    lower: lowerBoll
  }
}

export function calculateKDJ(kline: CandleChartType[], n = 9, m1 = 3, m2 = 3) {
  const kData: { time: number | string; value: string | number }[] = []
  const dData: { time: number | string; value: string | number }[] = []
  const jData: { time: number | string; value: string | number }[] = []
  const highList: BigNumber[] = []
  const lowList: BigNumber[] = []

  for (let i = 0; i < kline.length; i++) {
    if (i < n - 1) {
      continue // 前几个周期无法计算KDJ指标
    }

    highList.push(new BigNumber(kline[i].high))
    lowList.push(new BigNumber(kline[i].low))
    if (highList.length > n) {
      highList.shift()
      lowList.shift()
    }
    const highN = BigNumber.max(...highList)
    const lowN = BigNumber.min(...lowList)
    const rsv = new BigNumber(kline[i].close)
      .minus(lowN)
      .dividedBy(highN.minus(lowN))
      .times(100) // RSV
    const k = new BigNumber(m1)
      .times((kData[i - n - 2] && kData[i - n - 2].value) || 50)
      .plus(rsv)
      .dividedBy(m1 + 1)
      .toString() // K值
    const d = new BigNumber(m2)
      .times((dData[i - n - 2] && dData[i - n - 2].value) || 50)
      .plus(k)
      .dividedBy(m2 + 1)
      .toString() // D值
    const j = new BigNumber(3)
      .times(k)
      .minus(new BigNumber(2).times(d))
      .toString() // J值
    kData[i - (n - 1)] = { time: kline[i].time, value: k }
    dData[i - (n - 1)] = { time: kline[i].time, value: d }
    jData[i - (n - 1)] = { time: kline[i].time, value: j }
  }
  return {
    k: kData,
    d: dData,
    j: jData
  }
}

export function calculateWR(kline: CandleChartType[], n = 14) {
  const wr = [] // 存储WR指标计算结果的数组

  for (let i = 0; i < kline.length; i++) {
    if (i < n - 1) {
      // 前几个周期无法计算WR指标
      continue
    }

    const highN = kline.slice(i - n + 1, i + 1).map(candle => new BigNumber(candle.high))

    const lowN = kline.slice(i - n + 1, i + 1).map(candle => new BigNumber(candle.low))

    const close = new BigNumber(kline[i].close)

    const maxHighN = BigNumber.max(...highN)
    const minLowN = BigNumber.min(...lowN)

    const wrValue = maxHighN
      .minus(close)
      .dividedBy(maxHighN.minus(minLowN))
      .multipliedBy(-100)
      .toString() // WR值

    wr.push({ time: kline[i].time, value: wrValue })
  }

  return wr
}

// export function calculateWR(kline: CandleChartType[], n = 14) {
//   const wr: { time: number | string; value: number }[] = [] // 存储WR指标计算结果的数组
//
//   for (let i = 0; i < kline.length; i++) {
//     if (i < n - 1) {
//       // 前几个周期无法计算WR指标
//       continue
//     }
//
//     const highN = Math.max(...kline.slice(i - n + 1, i + 1).map(candle => candle.high))
//     const lowN = Math.min(...kline.slice(i - n + 1, i + 1).map(candle => candle.low))
//
//     const wrValue = ((highN - kline[i].close) / (highN - lowN)) * -100 // WR值
//
//     wr.push({ time: kline[i].time, value: wrValue })
//   }
//
//   return wr
// }
// 计算EMA（指数移动平均线）
function calculateEMA(data: CandleChartType[], n: number) {
  const result = []
  const multiplier = new BigNumber(2).dividedBy(n + 1)
  result[0] = { time: data[0].time, value: new BigNumber(data[0].close) }

  for (let i = 1; i < data.length; i++) {
    const diff = new BigNumber(data[i].close).minus(result[i - 1].value)
    const value = diff.times(multiplier).plus(result[i - 1].value)
    result[i] = { time: data[i].time, value }
  }

  return result
}

function calculateEMA2(data: { time: number | string; value: string }[], n: number) {
  const result: { time: number | string; value: string }[] = []
  const multiplier = new BigNumber(2).dividedBy(n + 1)
  result[0] = { time: data[0].time, value: new BigNumber(data[0].value).toString() }

  for (let i = 1; i < data.length; i++) {
    const diff = new BigNumber(data[i].value).minus(result[i - 1].value)
    const value = diff
      .times(multiplier)
      .plus(result[i - 1].value)
      .toFixed(11)
    result[i] = { time: data[i].time, value }
  }

  return result
}

// 计算MACD指标
export function calculateMACD(kline: CandleChartType[], shortPeriod = 12, longPeriod = 26, signalPeriod = 9) {
  if (!kline.length) {
    return { dif: [], dea: [], macd: [] }
  }

  // 计算短期EMA和长期EMA
  const shortEma = calculateEMA(kline, shortPeriod)
  const longEma = calculateEMA(kline, longPeriod)
  // 计算DIF（快速线）
  const dif = shortEma.map((ema, i) => {
    const value = new BigNumber(ema.value).minus(longEma[i].value).toFixed(11)
    return { time: ema.time, value }
  })

  // 计算DEA（信号线）
  const dea = calculateEMA2(dif, signalPeriod)

  // 计算MACD（柱状图）
  const macd = dif.map((val, i) => {
    const value = new BigNumber(val.value).minus(dea[i].value)
    return {
      time: val.time,
      value: value.toFixed(11),
      color: value.isLessThan(0) ? '#d96f62' : '#67b9a0'
    }
  })
  return {
    dif,
    dea,
    macd
  }
}

export function filterKlineDataToHL(klineData: CandleChartType[], from: number, to: number) {
  const filteredData = klineData.filter(candle => {
    const candleTime = candle.time // 假设K线数据中有时间字段为"time"
    return candleTime >= from && candleTime <= to
  })
  if (filteredData.length === 0) {
    return { maxHigh: null, maxHighTime: null, minLow: null, minLowTime: null }
  }

  const maxHighCandle = filteredData.reduce((prevCandle, currCandle) => {
    return new BigNumber(currCandle.high).gt(new BigNumber(prevCandle.high)) ? currCandle : prevCandle
  })

  const minLowCandle = filteredData.reduce((prevCandle, currCandle) => {
    return new BigNumber(currCandle.low).lt(new BigNumber(prevCandle.low)) ? currCandle : prevCandle
  })

  return {
    maxHigh: maxHighCandle.high,
    maxHighTime: maxHighCandle.time,
    minLow: minLowCandle.low,
    minLowTime: minLowCandle.time
  }
}

export function filterKlineDataToVol(volData: VolumeSeriesDataType[], from: number, to: number) {
  const filteredData = volData.filter(vol => {
    const candleTime = vol.time // 假设K线数据中有时间字段为"time"
    return candleTime >= from && candleTime <= to
  })
  if (filteredData.length === 0) {
    return { rangeMaxVol: null }
  }

  const maxHighCandle = filteredData.reduce((prevCandle, currCandle) => {
    return new BigNumber(currCandle.value).gt(new BigNumber(prevCandle.value)) ? currCandle : prevCandle
  })

  return {
    rangeMaxVol: maxHighCandle
  }
}

export function findFirstNonZeroIndex(str: string): number {
  // 使用正则表达式匹配字符串 "0." 之后的数字部分
  const match = str.match(/0\.(\d+)/)
  // 如果匹配成功且有小数部分
  if (match && match[1]) {
    // 遍历小数部分，找到第一个不为 0 的索引
    for (let i = 0; i < match[1].length; i++) {
      if (match[1][i] !== '0') {
        return i // 返回不为 0 的索引
      }
    }
  }

  return -1 // 如果没有匹配或没有小数部分，返回 -1 表示没有找到不为 0 的索引
}

export function filterKlineDataToHLWithDec(klineData: ChartData2Type[]) {
  const filteredData = klineData
  if (klineData.length === 0) {
    return { high: '', low: '', maxVol: '', minVol: '', decimals: 3, volDecimals: 2 }
  }

  const maxHighCandle = filteredData.reduce((prevCandle, currCandle) => {
    return new BigNumber(currCandle.high).gt(new BigNumber(prevCandle.high)) ? currCandle : prevCandle
  })
  const maxVol = filteredData.reduce((prevCandle, currCandle) => {
    return new BigNumber(currCandle.volume).gt(new BigNumber(prevCandle.volume)) ? currCandle : prevCandle
  })

  const minLowCandle = filteredData.reduce((prevCandle, currCandle) => {
    return new BigNumber(currCandle.low).lt(new BigNumber(prevCandle.low)) ? currCandle : prevCandle
  })
  const minVol = filteredData.reduce((prevCandle, currCandle) => {
    return new BigNumber(currCandle.volume).lt(new BigNumber(prevCandle.volume)) ? currCandle : prevCandle
  })
  const totalStr = new BigNumber(minLowCandle.low).toFixed(18).toString()
  const totalStr2 = new BigNumber(minVol.volume).toFixed(18).toString()
  const zeroIndex = findFirstNonZeroIndex(totalStr)
  const zeroIndex2 = findFirstNonZeroIndex(totalStr2)
  let decimals = 3,
    volDecimals = 2
  if (new BigNumber(minLowCandle.low).lt(0.001)) {
    decimals = zeroIndex < 3 ? 3 : zeroIndex > 15 ? 18 : zeroIndex + 3
  }
  if (new BigNumber(minLowCandle.low).gt(1)) {
    decimals = 2
  }
  if (new BigNumber(minVol.low).lt(0.001)) {
    volDecimals = zeroIndex2 < 3 ? 3 : zeroIndex2 > 15 ? 18 : zeroIndex2 + 3
  }
  return {
    high: maxHighCandle.high.toString(),
    low: minLowCandle.low.toString(),
    decimals,
    maxVol: maxVol.volume.toString(),
    minVol: minVol.volume.toString(),
    volDecimals
  }
}

export function findMaxAndMinValues(data: { time: number | string; value: string | number }[]) {
  if (!Array.isArray(data) || data.length === 0) {
    return {
      min: '0',
      max: '100'
    }
  }

  let maxValue = new BigNumber(data[0].value)
  let minValue = new BigNumber(data[0].value)

  for (let i = 1; i < data.length; i++) {
    const value = new BigNumber(data[i].value)
    if (value.isNaN()) {
      continue
    }
    if (maxValue.isNaN()) {
      maxValue = value
    }
    if (minValue.isNaN()) {
      minValue = value
    }
    // Compare the current value with the current max and min values
    if (!Boolean(maxValue.isNaN()) && value.isGreaterThan(maxValue)) {
      maxValue = value
    }

    if (!Boolean(minValue.isNaN()) && value.isLessThan(minValue)) {
      minValue = value
    }
  }

  return {
    max: maxValue.toString(),
    min: minValue.toString()
  }
}
