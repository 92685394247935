import React, { useMemo } from 'react'
import styled from 'styled-components'

import useHttpLocations from '../../hooks/useHttpLocations'
import Logo from '../Logo'
import { Currency } from '../../api/common'

// const getTokenLogoURL = (address: string) => `https://image.selltoken.org/${address}.png`
// const getTokenLogoURL = (address: string) => `http://trueswap.onemoredao.com/images/coins/${address}.png`
// const getTokenLogoURL2 = (address: string) =>
//   `http://onemorewalletasset.onemoredao.com/coin/omp/${address.toLowerCase()}.png`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations((currency && currency!.logoURI!) || undefined)
  const srcs: string[] = useMemo(() => {
    return [...uriLocations]
  }, [currency, uriLocations])
  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
