import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import { defaultOptions, otherOptions } from './static'
import { isMobile } from 'react-device-detect'
import { useQuotesActionHandlers, useQuotesState } from '../../state/quotes/hook'
// 数据接口 转化 模块
export function callbackTimer(str: string): string {
  switch (str) {
    case '1Sec':
      return 1 + ''
    case '1Min':
      return 60 + ''
    case '3Min':
      return 60 * 3 + ''
    case '5Min':
      return 60 * 5 + ''
    case '15Min':
      return 60 * 15 + ''
    case '30Min':
      return 60 * 30 + ''
    case '1Hour':
      return 60 * 60 + ''
    case '2Hour':
      return 60 * 60 * 2 + ''
    case '4Hour':
      return 60 * 60 * 4 + ''
    case '6Hour':
      return 60 * 60 * 2 + ''
    case '12Hour':
      return 60 * 60 * 2 + ''
    case '1Day':
      return 60 * 60 * 24 + ''
    case '3Day':
      return 60 * 60 * 24 * 3 + ''
    case '1Week':
      return 60 * 60 * 24 * 7 + ''
    case '1Month':
      return 60 * 60 * 24 * 30 + ''
    default:
      return 60 * 60 + ''
  }
}
const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.border1};
  padding: 0 10px;
`
const Tabs = styled.div<{ align?: string }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: ${({ align }) => (align ? align : 'center')};
  justify-content: space-evenly;
  display: flex;
  width: 100%;
`
export const StyledNavLink = styled.div<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 30px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme, isActive }) => (isActive ? theme.text1 : theme.text3)};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  font-size: 12px;
  flex: 1;

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
`
const MaskDiv = styled.div<{ isActive?: boolean; isMobile: boolean }>`
  position: absolute;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.3);
  height: ${({ isMobile }) => (!isMobile ? '100%' : '100vh')};
  width: 100%;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`
const KLineBlock = styled.div`
  position: relative;
  width: 100%;
  @keyframes fadeInDown {
    0% {
      -webkit-transform: translate3d(0, -20%, 0);
      -webkit-transform: translate3d(0, -20%, 0);
      transform: translate3d(0, -20%, 0);
      transform: translate3d(0, -20%, 0);
      opacity: 0;
    }
    100% {
      -webkit-transform: none;
      transform: none;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeInDown {
    0% {
      -webkit-transform: translate3d(0, -20%, 0);
      opacity: 0;
    }
    100% {
      -webkit-transform: none;
      opacity: 1;
    }
  }
`
const TimeInfoIndcate = styled.div<{ isActive?: boolean }>`
  background: ${({ theme }) => theme.bg1};
  width: 100%;
  display: none;
  position: absolute;
  z-index: 100;
  top: 0;
  padding: 10px 16px 5px;
  box-shadow: ${({ theme }) => theme.boxColor1};
  transition: all 0.3s ease-in;

  ${({ isActive }) => {
    if (!isActive) {
      return ''
    }
    return `    display: block;
    -webkit-animation: fadeInDown 0.3s;
    animation: fadeInDown 0.3s;`
  }}
`

const KlineChart2Tools = forwardRef(function(
  {
    depthShowStatus,
    toggleDepth,
    active,
    toggle,
    children
  }: {
    depthShowStatus: boolean
    toggleDepth: Function
    toggle: Function
    active: string
    children: React.ReactNode
  },
  ref: any
) {
  const { t } = useTranslation()
  const [isSelected, setIsSelected] = useState(false)
  const theme = useContext(ThemeContext)
  const { onChangeZIndex } = useQuotesActionHandlers()
  const { zIndex } = useQuotesState()
  useImperativeHandle(ref, () => ({
    setIsSelected
  }))
  return (
    <div>
      <TabsContainer>
        <Tabs>
          {defaultOptions.map((item, index) => (
            <StyledNavLink
              isActive={active === item}
              key={index}
              onClick={() => {
                if (isSelected) {
                  if (isMobile) {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    })
                    document.body.style.overflow = !isSelected ? 'hidden' : 'auto'
                    onChangeZIndex(zIndex >= 70 ? 69 : 70)
                  }
                  setIsSelected(!isSelected)
                }
                toggle(item)
                toggleDepth(false)
              }}
            >
              {t(item)}
            </StyledNavLink>
          ))}
          <StyledNavLink
            isActive={isSelected || otherOptions.includes(active)}
            key={12}
            onClick={() => {
              if (isMobile) {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
                document.body.style.overflow = !isSelected ? 'hidden' : 'auto'
                const currentOverFlow = document.body.style.overflow
                onChangeZIndex(currentOverFlow === 'hidden' ? 69 : 70)
              }
              setIsSelected(!isSelected)
              if (depthShowStatus) {
                return
              }
              toggleDepth(false)
            }}
          >
            {otherOptions.includes(active) ? active : t('More')}&nbsp;
            <svg
              className="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="6"
              fill={isSelected || otherOptions.includes(active) ? theme.text1 : theme.text3}
            >
              <path
                d="M878.592 250.88q29.696 0 48.128 11.264t24.576 29.696 0 41.472-26.624 45.568q-82.944 92.16-159.744 180.224t-148.48 164.864q-19.456 20.48-45.568 31.744t-53.76 11.776-53.248-8.704-43.008-28.672q-39.936-44.032-82.944-90.112l-88.064-92.16q-43.008-46.08-85.504-90.624t-79.36-86.528q-17.408-19.456-22.528-40.448t1.024-38.4 23.552-28.672 45.056-11.264q35.84 0 98.816-0.512t137.728-0.512l153.6 0 150.528 0 125.952 0 79.872 0z"
                p-id="2557"
              ></path>
            </svg>
          </StyledNavLink>
          <StyledNavLink
            isActive={depthShowStatus}
            key={13}
            onClick={() => {
              if (isMobile && isSelected) {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                })
                document.body.style.overflow = !isSelected ? 'hidden' : 'auto'
                const currentOverFlow = document.body.style.overflow
                onChangeZIndex(currentOverFlow === 'hidden' ? 69 : 70)
              }
              if (isSelected) {
                setIsSelected(!isSelected)
              }
              toggle('')
              toggleDepth(true)
            }}
          >
            {t('Depth')}
          </StyledNavLink>
        </Tabs>
      </TabsContainer>
      <KLineBlock>
        <MaskDiv
          isMobile={isMobile}
          isActive={isSelected}
          onClick={() => {
            if (isMobile) {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              })
              document.body.style.overflow = !isSelected ? 'hidden' : 'auto'
              const currentOverFlow = document.body.style.overflow
              onChangeZIndex(currentOverFlow === 'hidden' ? 69 : 70)
            }
            setIsSelected(!isSelected)
          }}
        ></MaskDiv>
        <TimeInfoIndcate isActive={isSelected}>
          <Tabs align={'flex-start'}>
            {otherOptions.map((item, index) => (
              <StyledNavLink
                isActive={active === item}
                key={index}
                onClick={() => {
                  if (isMobile) {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    })
                    document.body.style.overflow = !isSelected ? 'hidden' : 'auto'
                  }
                  setIsSelected(!isSelected)
                  toggle(item)
                  toggleDepth(false)
                }}
              >
                {t(item)}
              </StyledNavLink>
            ))}
          </Tabs>
        </TimeInfoIndcate>
        {children}
      </KLineBlock>
    </div>
  )
})
export default KlineChart2Tools
